'use client'
import { useSelectedTeam } from '@utils/useSearchParameters'
import { teamFeminines } from '@utils/teamsPathname'
import Link from 'next/link'

export const HomeButton = () => {
  const { selectedTeam, isOutsideTeam } = useSelectedTeam()
  return (
    <Link
      href={isOutsideTeam ? teamFeminines : selectedTeam}
      className="custom-text-gradient text-xl tracking-wide"
    >
      <strong> PBHB </strong>
    </Link>
  )
}
