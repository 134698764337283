"use client";
import { usePathname } from "next/navigation";
import { teamFeminines, teamLoisir, teamMasculines } from "./teamsPathname";

export const useSelectedTeam = () => {
    const pathname = usePathname();
    const [_, _team, ...rest] = pathname.split('/')
    const isHomepage = pathname === "/";
    const isFeminines = pathname.includes(teamFeminines) || isHomepage;
    const isLoisir = pathname.includes(teamLoisir);
    const isMasculine = pathname.includes(teamMasculines);

    const isOutsideTeam = !pathname.includes(teamFeminines) && !pathname.includes(teamLoisir) && !pathname.includes(teamMasculines);
    return { isFeminines, isLoisir, isMasculine, rest, isOutsideTeam, pathname, isHomepage, selectedTeam: isFeminines ? teamFeminines : isLoisir ? teamLoisir : teamMasculines };
}

