'use client'
import { useSelectedTeam } from '@utils/useSearchParameters'
import { teamFeminines, teamLoisir, teamMasculines } from '@utils/teamsPathname'
import { useRouter } from 'next/navigation'

export const TeamSwitch = () => {
  const router = useRouter()
  const { selectedTeam, pathname, isHomepage } = useSelectedTeam()
  const updateQueryParameter = (newValue) => {
    if (isHomepage) {
      router.push(newValue)
      return
    }

    const newPathname = pathname.replace(selectedTeam, newValue)

    router.push(newPathname)
  }

  //   return (
  //     <div className="flex items-center justify-center w-full">
  //       <label className="flex items-center cursor-pointer">
  //         <div className="relative">
  //           <input
  //             type="checkbox"
  //             className="sr-only"
  //             checked={isFeminines}
  //             onChange={updateQueryParameter}
  //           />
  //           <div
  //             className={`block w-14 h-8 rounded-full transition ${isFeminines ? 'bg-custom-yellow' : 'bg-custom-blue'}`}
  //           ></div>
  //           <div
  //             className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition transform ${isFeminines ? 'translate-x-0' : 'translate-x-full'}`}
  //           ></div>
  //         </div>
  //         <div className="ml-3 text-gray-700 font-medium w-[100px] text-3xl">
  //           {isFeminines ? '🤾‍♀️' : '🤾‍♀️🤾🏼‍♂️'}
  //         </div>
  //       </label>
  //     </div>
  //   )

  const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    updateQueryParameter(event.target.value)
  }

  if (!selectedTeam) {
    return null
  }

  return (
    <div className="flex flex-col h-full">
      <select
        id="team-select"
        value={selectedTeam}
        onChange={handleTeamChange}
        className="px-4 py-2 border-2 border-custom-blue rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-200 ease-in text-xl"
      >
        <option className="text-xl" value={teamFeminines}>
          🤾‍♀️ Femmes
        </option>
        <option className="text-xl" value={teamMasculines}>
          🤾🏼‍♂️ Homme
        </option>
        <option className="text-xl" value={teamLoisir}>
          🤾‍♀️🤾🏼‍♂️ Loisir
        </option>
      </select>
    </div>
  )
}
